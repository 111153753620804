<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <ul v-if="shift_data.length > 0" class="divide-y divide-gray-100">
    <li
      @click="viewProfile(person.user_id)"
      v-for="person in shift_data"
      :key="person.company"
      class="p-4 flex"
    >
      <div class="relative">
        <img class="h-10 w-10 rounded-full" :src="person.photo" alt="" />
        <span
          v-if="person.favourite === 1"
          class="badge badge-danger badge-pill favorite_icon"
        >
          <StarIcon />
        </span>
      </div>
      <div class="ml-3 self-center">
        <p class="text_username">{{ person.username }}</p>
        <p class="text-sm text-main-light">{{ person.role }}</p>
      </div>
    </li>
  </ul>
  <div class="text-center">
    <span
      v-if="shift_data.length === 0"
      class="text-center text-sm"
      style="color: #727497 !important"
      >You have not employed anyone yet</span
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { APIgetPreviousEmployed } from "@/api/employer/api";
import { StarIcon } from "@heroicons/vue/solid";

export default {
  data() {
    return {
      shift_data: [],
    };
  },
  components: {
    StarIcon,
  },
  computed: {
    ...mapGetters({
      getUserDetails: "authentication/getUserDetails",
    }),
  },
  methods: {
    ...mapActions({
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      fetchSetEmployerModalConfigs: "candidate/fetchSetEmployerModalConfigs",
    }),
    viewProfile(userid) {
      this.fetchSetEmployerModalConfigs({ user_id: userid });
      const data = {
        isOpen: true,
        iconName: "UserIcon",
        componentName:
          this.getUserDetails.user_type === 3
            ? "CandidateProfile"
            : "EmployerProfile",
        title: "",
        data: { modal_type: "big" },
      };
      this.fetchtSetModalConfigs(data);
    },
  },
  mounted() {
    APIgetPreviousEmployed().then((res) => {
      this.shift_data = res.data.results;
      console.log("aaa", this.shift_data);
    });
  },
};
</script>
