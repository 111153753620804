<template>
  <Loader :loader_flag="loader_flag" />
  <div class="overflow-x-auto panelslider">
    <div
      class="flex mt-4 mb-4 overflow-x-auto panelslider"
      style="min-width: 500px"
    >
      <button
        @click="addShift"
        type="button"
        class="
          flex
          mt-2
          mr-2
          p-2 p-1
          cursor-pointer
          primaryButton
          w-44
          justify-center
        "
      >
        <span class="flex"
          ><span> <PlusSmIcon class="w-4" /></span>
          <span class="w-28 ml-1 self-center text-sm">Add new shift</span></span
        >
      </button>
    </div>
    <div></div>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <div
            class="
              mobile_view
              large_content_shadow
              min-w-full
              divide-y divide-gray-200
              bg-white
            "
            style="border: 1px solid #e5e7eb"
          >
            <div class="relative" v-click-away="onClickAway2">
              <div
                class="flex items-center p-2 pl-4 mobile_td cursor-pointer"
                @click="open_sortbyDropdown"
              >
                <img
                  class=""
                  style="width: 9px; height: 12px"
                  :src="require('../../assets/images/sort.svg')"
                />
                <span class="self-center ml-2 mobile_sortBy">SORT BY</span>
              </div>
              <div v-if="open_sortbyFlag" class="open_sortby_list">
                <div
                  @click="mobilesortClick('role')"
                  class="flex mb-5 cursor-pointer"
                >
                  <img
                    class="w-3"
                    :src="require('../../assets/images/sort.svg')"
                  />
                  <span
                    style="color: rgb(116, 117, 118)"
                    class="ml-2 text-base font-bold"
                    >ROLE</span
                  >
                </div>
                <div
                  @click="mobilesortClick('start_time')"
                  class="flex mb-5 cursor-pointer"
                >
                  <img
                    class="w-3"
                    :src="require('../../assets/images/sort.svg')"
                  />
                  <span
                    style="color: rgb(116, 117, 118)"
                    class="ml-2 text-base font-bold"
                  >
                    SHIFT TIMES</span
                  >
                </div>
                <div
                  @click="mobilesortClick('hours')"
                  class="flex mb-5 cursor-pointer"
                >
                  <img
                    class="w-3"
                    :src="require('../../assets/images/sort.svg')"
                  />
                  <span
                    style="color: rgb(116, 117, 118)"
                    class="ml-2 text-base font-bold"
                    >DURATION</span
                  >
                </div>

                <div
                  @click="mobilesortClick('status')"
                  class="flex mb-5 cursor-pointer"
                >
                  <img
                    class="w-3"
                    :src="require('../../assets/images/sort.svg')"
                  />
                  <span
                    style="color: rgb(116, 117, 118)"
                    class="ml-2 text-base font-bold"
                    >STATUS</span
                  >
                </div>
                <div
                  @click="mobilesortClick('rate')"
                  class="flex cursor-pointer"
                >
                  <img
                    class="w-3"
                    :src="require('../../assets/images/sort.svg')"
                  />
                  <span
                    style="color: rgb(116, 117, 118)"
                    class="ml-2 text-base font-bold"
                    >RATE</span
                  >
                </div>
              </div>
            </div>

            <div v-if="page_data.length === 0" class="text-center pt-3">
              <span class="text-sm text-center" style="color: #727497"
                >No shifts matching your criteria</span
              >
            </div>
            <RowMobile
              v-for="shift in page_data"
              :key="shift.id"
              :shift="shift"
              :actionType="actionType"
            ></RowMobile>
            <div class="pagination_div pb-10 pt-4">
              <v-pagination
                v-model="page"
                :pages="Math.ceil(this.shift_data.length / 10)"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="updatePagination"
              />
            </div>
          </div>
          <table class="min-w-full divide-y divide-gray-200" id="datatable">
            <thead class="bg-gray-50">
              <tr class="hidden lg:grid lg:grid-cols-shift-table-em_dashboard">
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  ROLE
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  SHIFT TIMES
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  DURATION
                </th>
                <th
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  APPLICANTS
                </th>
                <th
                  scope="col"
                  id="back_none"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                ></th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <Row
                v-for="shift in shift_data"
                :key="shift.id"
                :shift="shift"
                actionType="complete"
              ></Row>
            </tbody>
          </table>

          <RowPanel :shift="shiftPanelData" :actionType="actionType" />
        </div>
      </div>
    </div>
  </div>
</template>
<style type="text/css">
@import "../../common.css";
</style>
<script>
import Row from "./ShiftsDashboardTable/Row";
import RowMobile from "./ShiftsDashboardTable/Row_mobile";

import RowPanel from "./ShiftsYours/RowPanel";
import { mapGetters, mapActions } from "vuex";
import { mixin as VueClickAway } from "vue3-click-away";
import { PlusSmIcon } from "@heroicons/vue/solid";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import Loader from "@common/Loader.vue";
import { APIdoGetSubscriptionActive } from "@/api/common/api";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
export default {
  components: {
    Row,
    RowPanel,
    PlusSmIcon,

    Loader,
    VPagination,
    RowMobile,
  },
  data() {
    return {
      // page: 1,
      loader_flag: false,
      shift_data: [],
      table: "",
      getShiftFilterValues: { distance: 100 },

      filterBox: false,
      topfilterBox: false,

      filterRating: false,
      // filterReliability: false,
      filterDistance: false,
      ratingValue: 50,
      // reliabilityValue: '',
      distanceValue: 20,
      myValue: "",
      shift_filter: "",
      page: 1,
      page_data: [],
      open_sortbyFlag: false,
      sort_index: "",
      // pagination_length:0,
    };
  },
  computed: {
    ...mapGetters({
      shiftPanelData: "candidate/getCurrentPanel",
      getShiftRole: "candidate/getShiftRole",
      filteredShifts: "employer/getFilteredShifts",
    }),
  },
  watch: {
    filteredShifts: function () {
      this.updateHandler();
      this.updatePagination();
    },
  },
  mixins: [VueClickAway],

  methods: {
    ...mapActions({
      fetachShiftRole: "candidate/fetachShiftRole",
      fetchtSetModalConfigs: "candidate/fetchtSetModalConfigs",
      FetchgetShiftsDashboard: "employer/FetchgetEmployerShifts",
    }),
    mobilesortClick(index) {
      this.open_sortbyFlag = false;
      this.sort_index = index;
      this.filteredShifts.sort(this.compare);
      this.updatePagination();
    },
    compare(a, b) {
      if (a[this.sort_index] > b[this.sort_index]) {
        return 1;
      }
      if (a[this.sort_index] < b[this.sort_index]) {
        return -1;
      }
      return 0;
    },
    updatePagination() {
      this.page_data = [];
      let a =
        this.filteredShifts.length < this.page * 10
          ? this.filteredShifts.length
          : this.page * 10;
      for (let i = (this.page - 1) * 10; i < a; i++) {
        this.page_data.push(this.filteredShifts[i]);
      }
    },
    onClickAway2() {
      this.open_sortbyFlag = false;
    },
    open_sortbyDropdown() {
      this.open_sortbyFlag = !this.open_sortbyFlag;
    },
    updateHandler() {
      this.shift_data = [];
      if (this.table != "") {
        $("#datatable").DataTable().destroy();
      }

      for (let i = 0; i < this.filteredShifts.length; i++) {
        this.filteredShifts[i]["index"] = 9999 - i;
        this.shift_data.push(this.filteredShifts[i]);
      }
      setTimeout(() => {
        this.table = $("#datatable").DataTable({
          order: [[1, "asc"]],
          language: {
            emptyTable: "You currently have no open shifts",
            paginate: {
              previous: "&#x2190; Previous",
              next: "Next &#x2192;",
            },
          },
        });
      }, 10);
    },
    addShift() {
      APIdoGetSubscriptionActive().then((res) => {
        if (res.data.results[0].compliance_complete === 0) {
          const data = {
            isOpen: true,
            iconName: "TicketIcon",
            componentName: "InactiveComplianceModalContent",
            title: "",
            data: { modal_type: "small" },
          };
          this.fetchtSetModalConfigs(data);
        } else if (res.data.results[0].sub_status === 0) {
          const data = {
            isOpen: true,
            iconName: "TicketIcon",
            componentName: "InactiveSubscriptionModalContent",
            title: "",
            data: { modal_type: "small" },
          };
          this.fetchtSetModalConfigs(data);
        } else {
          const data = {
            isOpen: true,
            iconName: "ClipboardCheckIcon",
            componentName: "AddShiftModalContent",
            title: "",
            data: { modal_type: "base" },
          };
          this.fetchtSetModalConfigs(data);
        }
      });
    },
  },
  mounted() {
    this.fetachShiftRole();
    this.FetchgetShiftsDashboard("");
    //    this.updateHandler();
  },
  props: {
    actionType: {
      type: String,
      default: "View",
    },
  },
};
</script>
